@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #000;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.6rem;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1200px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

.subArea {
  background-position-y: 60%;
  background-size: cover;
  background-color: black;
}

.subArea h1 {
  font-size: 5.2rem;
  font-weight: 500;
  color: #fff;
  padding: 150px 0 150px;
  text-align: center;
  line-height: 1.2em;
}

@media screen and (max-width: 834px) {
  .subArea h1 {
    font-size: 4rem;
    padding: 100px 5%;
  }
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #000;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

/*========= ローディング画面のためのCSS ===============*/
#splash {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #22B459;
  z-index: 9999999;
  text-align: center;
  color: #fff;
}

#splash-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#copy a {
  color: #fff;
}

@media screen and (max-width: 640px) {
  .index-01 .inner .img {
    position: inherit !important;
  }
}

.business_box ul {
  display: grid !important;
  width: min(100% - 2rem, 1400px);
  grid-template-columns: repeat(var(--column, 4), 1fr) !important;
  gap: var(--gap, 10px) !important;
  margin-inline: auto;
}

@media screen and (max-width: 834px) {
  .business_box ul {
    grid-template-columns: repeat(var(--column, 2), 1fr) !important;
  }
}

.business_box ul li {
  position: relative;
}

.business_box ul li article > div {
  position: absolute;
  bottom: 10px;
  left: 0;
  margin: auto;
  background-color: #fff;
  color: #000;
  padding: 10px;
  display: block;
  width: 90%;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .business_box ul li article > div {
    font-size: 13px;
  }
}

.business_box ul li article > div:before {
  content: "";
  border-left: 5px solid #22B459;
  margin-right: 10px;
}

.business_box ul li img {
  width: 100% !important;
}

.newsWrap {
  background-color: #E5FBEE;
  padding: 60px 24px;
}

.newsWrap .news {
  max-height: 300px;
  overflow-y: auto;
}

.newsWrap .news dt, .newsWrap .news dd {
  border: none !important;
}

@media screen and (max-width: 640px) {
  .newsWrap .news dt, .newsWrap .news dd {
    width: 100% !important;
  }
}

.newsWrap .news dl {
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 640px) {
  .newsWrap .news dl {
    display: block !important;
  }
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.btn a {
  display: block;
  max-width: 300px;
  color: #fff;
  padding: 15px 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .btn a {
    margin-right: auto;
    margin-left: auto;
  }
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1200px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1200px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.local_title_01 h2 {
  font-size: 2em;
  line-height: 1.2em;
  position: relative;
  padding: 20px 30px 20px 40px;
  background: #E5FBEE;
}

@media screen and (max-width: 834px) {
  .local_title_01 h2 {
    font-size: 1.5em;
  }
}

.local_title_01 h2:before {
  position: absolute;
  top: 25%;
  left: 20px;
  width: 6px;
  height: 50%;
  content: '';
  border-radius: 3px;
  background: #22B459;
}

.local_title_02 h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  font-weight: bold;
  color: #fff;
}

.local_title_03 h2 {
  font-size: 2em;
  line-height: 1.25em;
  padding-bottom: 15px;
  font-weight: bold;
  position: relative;
}

.local_title_03 h2:before {
  position: absolute;
  display: block;
  width: 100px;
  height: 6px;
  margin: var(--margin, auto);
  content: '';
  background: var(--bg, #22b459);
  inset-block-end: 0;
  inset-inline: var(--posi, 0);
}

.sub_title_01 h3 {
  font-size: 1.25em;
  font-weight: bold;
  background: #22B459;
  color: #fff;
  padding: 10px;
  line-height: 1em;
}

.sub_title_02 h3 {
  font-size: 3em;
  font-weight: 900;
  line-height: 1.5em;
  background-color: #22B459;
  color: #fff;
  padding: 15px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .sub_title_02 h3 {
    font-size: 2em;
  }
}

@media screen and (max-width: 834px) {
  .sub_title_02 h3 {
    font-size: 2em;
  }
}

.sub_title_02 h3 .small {
  font-size: 18px;
  display: block;
  line-height: 1em;
}

@media screen and (max-width: 834px) {
  .sub_title_02 h3 .small {
    font-size: 14px;
  }
}

.sub_title_03 h3 {
  font-size: 1.25em;
  font-weight: bold;
  background-color: #22B459;
  color: #fff;
  padding: 10px;
}

.btnbox {
  justify-content: space-between;
}

.btnbox .box {
  width: 24%;
  margin-bottom: 1.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1F613B;
  flex-direction: column;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .btnbox .box {
    width: 48%;
  }
}

@media screen and (max-width: 640px) {
  .btnbox .box {
    width: 100%;
  }
}

.btnbox .box div {
  width: 100%;
}

.btnbox .box a {
  background-color: #1F613B;
  color: #fff;
  padding: 15px;
  text-align: center;
  display: block;
  width: 100%;
}

.local_cont01 .inner2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .local_cont01 .inner2 {
    display: block;
  }
  .local_cont01 .inner2 .img {
    width: 100% !important;
  }
  .local_cont01 .inner2 .lead {
    width: 100% !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont01 .inner2 .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

.local_cont01 .inner2 .img {
  width: 48%;
}

.local_cont01 .inner2 .img img {
  width: 100% !important;
}

.local_cont01 .inner2 .lead {
  width: 48%;
}

.flexbox_01 {
  justify-content: space-between;
}

.flexbox_01 .box {
  width: calc(100%/3 - 15px);
  margin-bottom: 2%;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 640px) {
  .flexbox_01 .box {
    width: 100%;
  }
  .flexbox_01 .box:first-child {
    margin-bottom: 15px;
  }
}

.flexbox_01 .box img {
  transition: 0.5s;
  width: 100% !important;
  height: 270px !important;
  object-fit: cover;
}

.flexbox_01 .box div {
  font-weight: bold;
  pointer-events: none;
  text-align: center;
  border-bottom: 2px solid #22B459;
  padding: 10px;
}

.flexbox_01 .box a {
  display: block;
  overflow: hidden;
}

.flexbox_01 .box a:hover img {
  transform: scale(1.05);
}

.dl_list01 dt {
  border: none !important;
  border-bottom: 1px solid #22B459 !important;
  padding: 15px !important;
}

.dl_list01 dd {
  border: none !important;
  border-bottom: 1px solid #E5FBEE !important;
  padding: 15px !important;
}

@media screen and (max-width: 834px) {
  .dl_list01 dl {
    display: block !important;
  }
  .dl_list01 dl dt {
    width: 100% !important;
  }
  .dl_list01 dl dd {
    width: 100% !important;
  }
}

.local_cont02 {
  background: #22B459;
  padding: 40px;
}

@media screen and (max-width: 834px) {
  .local_cont02 {
    padding: 40px 24px;
  }
}

.local_cont02 .inner2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .local_cont02 .inner2 {
    display: block;
  }
  .local_cont02 .inner2 .img {
    width: 100% !important;
  }
  .local_cont02 .inner2 .attention {
    width: 100% !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont02 .inner2 .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

.local_cont02 .inner2 .img {
  width: 48%;
}

.local_cont02 .inner2 .img img {
  width: 100% !important;
}

.local_cont02 .inner2 .attention {
  width: 48%;
}

.local_cont03 {
  background: #E5FBEE;
  padding: 50px;
  border: 1px solid #22B459;
}

.attention div {
  font-size: 2.5em;
  font-weight: bold;
  line-height: 1.5em;
  color: #fff;
}

.list_01 li {
  padding-left: 10px;
  font-size: 1.5em;
  margin-bottom: 15px;
}

.list_01 li:before {
  content: "\f4fc";
  font-family: "Font Awesome 5 pro";
  font-weight: 900;
  color: #22B459;
}

.btnbox2 {
  justify-content: space-between;
}

.btnbox2 .box {
  width: calc(100%/2 - 15px);
  margin-bottom: 1.5%;
  flex-direction: column;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .btnbox2 .box {
    width: 48%;
  }
}

@media screen and (max-width: 640px) {
  .btnbox2 .box {
    width: 100%;
  }
}

.btnbox2 .box div {
  width: 100%;
}

.btnbox2 .box a {
  background-color: #fff;
  color: #000;
  font-size: 1.2em;
  padding: 15px;
  text-align: center;
  display: block;
  width: 100%;
}

.list_02 li {
  text-align: center !important;
  padding-left: 10px;
  font-size: 1.5em;
  margin-bottom: 15px;
}

.list_02 li:before {
  content: "\f4fc";
  font-family: "Font Awesome 5 pro";
  font-weight: 900;
  color: #22B459;
}

.policy_cont h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #22B459;
  padding-bottom: 15px;
  font-weight: bold;
  margin: 50px 0 20px 0;
}

.map iframe {
  width: 100%;
}

.el_text a {
  background: #22B459;
  color: #fff;
  padding: 15px;
}

/* fadeUp */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeUpTrigger, .fadeUpTrigger2 {
  opacity: 0;
}

.fadeLeft {
  animation-name: fadeLeftAnime;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeLeftAnime {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.cont_form dt {
  border: none !important;
}

.cont_form dd {
  border: none !important;
}

.cont_form dl {
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

.mail {
  display: none;
}

.confirm {
  margin-top: 50px;
}

.policy {
  padding: 10px 0;
  text-align: center;
}

body {
  overflow: hidden;
}

.btn {
  background: #22B459;
  color: #ffffff !important;
  display: block;
  text-align: center;
  padding: 15px;
}
